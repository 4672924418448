import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {AlarmModuleNode} from '../../interfaces/AlarmModuleNode';
import {alarmButtonPressCancelSchema} from '../../scheme/yup/alarm-module';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';

interface Props {
  onClose?: () => void;
  closeParent?: () => void;
  messageId?: number;
}

const AlarmButtonPressCancelForm = ({
  onClose,
  closeParent,
  messageId,
}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const formik = useFormik({
    initialValues: {
      ack_comment: null,
      message_id: messageId,
    },
    validationSchema: alarmButtonPressCancelSchema,
    onSubmit: async (data) => {
      setSubmittedInProgress(true);
      try {
        if (data?.message_id) {
          const requestBody = {
            ids: {
              alarm: [data.message_id],
            },
            ack_comment: data.ack_comment,
          };
          const endpoint = `${apiBaseUrl}/event/acknowledge`;
          await API.patch<AlarmModuleNode>(endpoint, requestBody);
        }

        const successMessage = 'Event has been acknowledged';
        enqueueSnackbar(successMessage, {
          variant: 'success',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
        onClose?.();
        closeParent?.();
      } catch (error) {
        const messages = getMessagesFromApiError(error);
        setSubmitErrors(messages);
      } finally {
        setSubmittedInProgress(false);
      }
    },
  });

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Typography variant="h6" component="h2" flex={1}>
          Acknowledge Button Pressed Event
        </Typography>
        <FormControl
          sx={{
            width: '100%',
            mt: 2,
          }}
        >
          <Box display="flex" width="100%" gap={3}>
            <TextField
              value={formik.values.ack_comment}
              label="Acknowledge comment"
              size="small"
              name="ack_comment"
              fullWidth
              error={
                !!formik.touched.ack_comment && !!formik.errors.ack_comment
              }
              helperText={
                formik.touched.ack_comment && formik.errors.ack_comment
              }
              onChange={formik.handleChange}
            />
          </Box>
        </FormControl>
      </Box>
      {submitErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" justifyContent="end" gap={0.5}>
        <Button
          onClick={() => {
            onClose?.();
          }}
        >
          Cancel
        </Button>

        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ml: 1}}
          >
            Acknowledge
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AlarmButtonPressCancelForm;
